<template>
  <div class="account-wrapper">
    <div class="sidebar-left">
      <el-scrollbar class="sidebar">
        <div class="item user-portrait">
          <el-avatar
            class="right-image"
            :src="organ.organ_info.logo_url"
            @error="true"
          >
            <img
              style="max-height: 100px"
              src="../../assets/image/pc_touxiang.png"
            />
          </el-avatar>
          <div class="font">{{ organ.organ_info.title }}</div>
        </div>
        <div class="item">问题反馈</div>
        <div class="item">智能客服</div>
      </el-scrollbar>
    </div>
    <div class="sidebar-right">
      <el-scrollbar class="sidebar">
        <div class="user-info">
          <div class="portrait item">
            <el-avatar
              class="left-image"
              :src="organ.organ_info.logo_url"
              @error="true"
            >
              <img
                style="max-height: 65px"
                src="../../assets/image/pc_touxiang.png"
              />
            </el-avatar>
          </div>
          <div class="textInfo item">
            <div class="item-title">
              <div class="sidebar-title">基本信息</div>
            </div>
            <ul class="content-wrapper">
              <li class="item-wrapper">
                <div class="label">机构名称</div>
                <div class="text">{{ organ.organ_info.title }}</div>
                <!-- <el-input v-show="isName" class="text" placeholder="请输入用户名" v-model="user.user_name" clearable></el-input> -->
                <!-- <i class="el-icon-edit-outline"></i> -->
              </li>
              <!-- <li class="item-wrapper">
                <div class="label">机构编号</div>
                <div class="text">{{ organ.organ_id }}</div>
              </li> -->
            </ul>
          </div>
        </div>
        <div class="user-securityInfo">
          <div class="item-title">
            <div class="sidebar-title">安全信息</div>
          </div>
          <ul class="content-wrapper security">
            <li class="item-wrapper">
              <div class="item-label">
                <div class="label">邮箱账号</div>
                <div class="text">{{ organ.organ_info.email }}</div>
              </div>
              <el-button type="warning" size="small">修改</el-button>
            </li>
            <li class="item-wrapper">
              <div class="item-label">
                <div class="label">管理员</div>
                <div class="text">
                  {{
                    organ.users_info.length ? organ.users_info[0].user_name : "XXXX"
                  }}
                </div>
              </div>
              <el-button
                type="warning"
                size="small"
                v-show="organ.users_info.length"
                @click="dialogVisible = true"
                >修改</el-button
              >
              <el-button
                v-show="!organ.users_info.length"
                type="warning"
                size="small"
                @click="dialogVisible = true"
                >未绑定</el-button
              >
            </li>
          </ul>
        </div>
      </el-scrollbar>
    </div>
    <el-dialog
      title="绑定管理员"
      :visible.sync="dialogVisible"
      width="35em"
      @close="empty"
    >
      <el-form
        ref="form"
        class="bottom"
        :model="form"
        :rules="ruleForm"
        status-icon
        @submit.native.prevent
        label-width="5em"
      >
        <el-form-item prop="phone" label="关键字">
          <div class="item-wrapper bottom-o">
            <div class="item-content">
              <el-input
                v-model="form.phone"
                title="三个字母的国家代码"
                placeholder="请输入手机号"
                @keyup.enter.native="query"
              ></el-input>
            </div>
            <el-button
              type="primary"
              icon="el-icon-search"
              @click.prevent="query"
              >搜索</el-button
            >
          </div>
        </el-form-item>
      </el-form>
      <div class="content">
        <el-card
          class="box-card"
          v-show="user"
          v-for="item in user"
          :key="item.id"
        >
          <div class="item-wrapper">
            <label class="item-text blod">{{ item.user_name }}</label>
          </div>
          <div class="item-wrapper">
            <label class="item-label">ID</label>
            <label class="item-text">{{ item.uid }}</label>
          </div>
          <div class="item-wrapper">
            <label class="item-label">手机号</label>
            <label class="item-text">{{ item.phone_num }}</label>
          </div>
        </el-card>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { organInfo, getOrgan, remove, bind } from "@/api/organ";
import { isPhone } from "@/utils/validate";
export default {
  props: {},
  data() {
    const validatePhone = (rule, value, callback) => {
      if (!isPhone(value)) {
        callback(new Error("手机号不符合规则"));
      } else {
        callback();
      }
    };
    return {
      organ: {
        organ_info: {},
        users_info: [],
      },
      dialogVisible: false,
      form: {
        phone: "",
      },
      ruleForm: {
        phone: [{ required: true, trigger: "blur", validator: validatePhone }],
      },
      user: "",
    };
  },
  watch: {},
  computed: {},
  methods: {
    async getInfo() {
      let { data } = await organInfo();
      this.organ = data;
    },
    async save() {
      if (this.user) {
        await bind({
          organ_id: this.organ.organ_info.id,
          operator_phone: this.user[0].phone_num,
        });
        this.getInfo();
        this.$message({
          type: "success",
          message: "绑定成功!",
        });
        this.dialogVisible = false;
      } else {
        this.$message.warning("亲,请搜索并确认用户!");
      }
    },
    empty() {
      this.form = {
        phone: "",
      };
      this.user = [];
    },
    query() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          let { data } = await getOrgan(this.form.phone);
          if (data) {
            this.user = data;
          } else {
            this.$message.warning("未查找到此用户!");
            this.user = [];
          }
        }
      });
    },
    handleRemove() {
      let _this = this;
      this.$confirm(`此操作将解除此管理员, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await remove();
          _this.getInfo();
          _this.$message({
            type: "success",
            message: "解除成功!",
          });
        })
        .catch(() => {
          _this.$message({
            type: "info",
            message: "已取消解除",
          });
        });
    },
  },
  created() {
    this.$emit("customStyle", 3);
    this.$nextTick(() => {
      this.getInfo();
    });
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.account-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-top: 15px;
  box-sizing: border-box;
  background-color: #eee;
  .right-image {
    width: 100px;
    height: 100px;
    line-height: 100px;
    img {
      height: unset;
      max-height: 100px;
    }
  }
  .left-image {
    width: 65px;
    height: 65px;
    line-height: 65px;
    img {
      height: unset;
      max-height: 65px;
    }
  }
  /deep/ .sidebar {
    background-color: #fff;
    height: 100%;
    .el-scrollbar__wrap {
      overflow-x: hidden;
      .el-timeline-item:last-of-type {
        .el-timeline-item__tail {
          display: none;
        }
      }
    }
  }
  .item-wrapper {
    display: flex;
    line-height: 30px;
    margin-bottom: 8px;
    &:first-child {
      margin-bottom: 10px;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
    .item-label {
      text-align: right;
      display: inline-flex;
      align-items: center;
      width: 5em;
      font-size: 14px;
      color: #606266;
      padding: 0 12px 0 0;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }
    .item-text {
      display: inline-flex;
      align-items: center;
      font-size: 14px;
      color: #606266;
      padding: 0 12px 0 0;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }
    .blod {
      font-weight: bold;
      font-size: 15px;
      color: #ebb563;
    }
    .item-content {
      width: 220px;
      margin-right: 25px;
    }
  }
  .content {
    padding-left: 5em;
    .el-card {
      width: 333px;
      .el-card__body {
        padding: 10px 20px;
      }
    }
  }
  /deep/ .el-dialog__footer {
    text-align: center;
    .el-button + .el-button {
      margin-left: 30px;
    }
  }
  .bottom-o {
    margin-bottom: 0;
  }
  .bottom {
    margin-bottom: 25px;
  }
  .sidebar-title {
    display: inline-block;
    font-size: 18px;
    text-indent: 1px;
    letter-spacing: 1px;
    padding-left: 5px;
    margin: -2px 0;
    color: #333333;
    border-left: 5px solid #e5b467;
  }
  .sidebar-left {
    width: 350px;
    max-height: 812px;
    margin-right: 30px;
    float: left;
    .item {
      position: relative;
      display: flex;
      width: 100%;
      height: 80px;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      text-indent: 1px;
      letter-spacing: 1px;
      color: #999999;
      font-size: 18px;
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 1px;
        background-color: #e5e5e5;
      }
    }
    .user-portrait {
      height: 250px;
      flex-direction: column;
      .font {
        font-size: 16px;
        margin-top: 20px;
        color: #333333;
      }
    }
  }
  .sidebar-right {
    width: 1000px;
    max-height: 812px;
    float: left;
    .content-wrapper {
      display: inline-block;
      .item-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        margin-block-end: 1.5em;
        .label {
          width: 4em;
          text-align: left;
          margin-right: 15px;
          color: #999999;
        }
        .text {
          color: #333333;
        }
      }
    }
    .user-info {
      position: relative;
      width: 100%;
      min-height: 250px;
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 1px;
        background-color: #e5e5e5;
      }
      .item {
        display: flex;
        width: 500px;
        margin: 0 auto;
      }
      .portrait {
        height: 120px;
        justify-content: center;
        align-items: center;
      }
      .textInfo {
        display: flex;
        .item-title {
          margin-right: 80px;
        }
      }
      .text {
        width: 180px;
      }
      i {
        font-size: 20px;
        color: #e5b467;
        cursor: pointer;
      }
    }
    .user-securityInfo {
      width: 500px;
      padding: 30px 0;
      margin: 0 auto;
      .item-title {
        width: 100%;
        margin-block-end: 2em;
      }
      .el-button {
        width: 68px;
      }
      .security {
        width: 100%;
        margin-block-end: 2em;
        .item-label {
          flex-grow: 1;
          display: flex;
          align-items: baseline;
          .label {
            width: 5em;
            margin-right: 25px;
          }
        }
      }
      .third-party {
        width: 100%;
        height: auto;
        .item-wrapper {
          width: 100%;
          display: flex;
          align-items: center;
          font-size: 17px;
          margin-block-end: 1.5em;
          // padding-left: 45px;
          box-sizing: border-box;
          .flex-item {
            flex-grow: 1;
            display: flex;
            align-items: center;
            .iconfont- {
              width: 9em;
              display: inline-flex;
              align-items: center;
              .icon {
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                box-sizing: border-box;
                margin-right: 20px;
                .iconfont {
                  font-size: 25px;
                  color: #fff;
                }
              }
            }
            .text {
              flex-grow: 1;
            }
            .weixin {
              background-color: #22ac38;
              padding-top: 5px;
            }
            .taobao {
              background-color: #73befb;
            }
          }
        }
      }
    }
  }
  &::after {
    content: "";
    clear: both;
  }
}
</style>